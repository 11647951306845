.dashboard-buttons {
  position: absolute;
  padding: 5rem 1rem 0rem;
  bottom: 0rem;
}

.react-multi-carousel-list {
  align-items: unset !important;
}
.shadow-div {
  position: absolute;
  padding: 4rem 0;
  width: 100vw;
}

.dashboard_cart {
  height: 65px;
}

.dashboard_cart + span {
  top: 14%;
}

.menu_cart {
  height: 40px;
}

.campaign-carousel .react-multi-carousel-track li {
  height: calc(var(--page_height) - 55px);
}

.campaign-carousel .react-multi-carousel-dot-list {
  z-index: 1;
}

.campaign-carousel
  .react-multi-carousel-dot-list
  .react-multi-carousel-dot
  button {
  background-color: var(--brown1);
  border-color: var(--brown1);
  border-width: 2px;
}

.campaign-carousel {
  width: 100vw !important;
}

.campaign-carousel
  .react-multi-carousel-dot-list
  .react-multi-carousel-dot--active
  button {
  background-color: transparent;
  border-color: var(--brown1);
}

.dashboard-image-text {
  bottom: 0.8vh;
  right: unset;
  width: 100%;
  z-index: 1;
}

.dashboardImage {
  height: 55vh;
  /* width: calc(100vw - 11px); */
  /* width: calc(100vw ); */

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.favorite-item-detail {
  max-width: 100%;
  width: 100vw;
  text-align: center;
}

.favorite-item-detail div {
  flex-direction: row;
}
.favorite-item-detail div span:last-child {
  padding-left: 4px;
}

.favorite-item-detail div span {
  font-family: "Branden Grotesque Bold" !important;
}

.favorite-item-detail span {
  font-size: 1.5rem;
}

.favorite-item-detail > span {
  font-size: 1.1rem;
}

.campaign-carousel .react-multi-carousel-item {
  visibility: hidden;
}

.campaign-carousel
  .react-multi-carousel-item.react-multi-carousel-item--active {
  visibility: visible;
}

/*Safari 11 + */
@supports (-webkit-appearance: none) and (stroke-color: transparent) {
  .campaign-carousel .react-multi-carousel-dot-list {
    margin-bottom: 15vh;
    z-index: 1;
  }

  .dashboardImage {
    height: 55vh;
    width: calc(100vw - 8px);
  }

  .dashboard-buttons {
    position: absolute !important;
    bottom: 1.5rem !important;
    padding: 0;
  }
  @media screen and (min-width: 768px) {
    .dashboard-buttons {
      padding: 5rem 1rem 0rem !important;
    }
    .campaign-carousel .react-multi-carousel-dot-list {
      margin-bottom: 1vh;
    }
  }
}
@-moz-document url-prefix() {
  .dashboardImage.isWin {
    /* height: 55vh; */
    width: calc(100vw - 8px);
  }
  .dashboardImage.isMac {
    /* height: 55vh; */
    width: calc(100vw - 11px);
  }
}
.dashboard-buttons button {
  display: block !important;
}
@media screen and (min-width: 580px) {
  .dashboard-buttons {
    padding-bottom: 1rem;
  }

  .home-page div:first-child {
    position: absolute;
  }

  .dashboardImage {
    height: var(--page_height);
  }

  .campaign-carousel .react-multi-carousel-dot-list {
    margin-bottom: 9rem;
  }
}

@media only screen and (max-width: 1187px) {
  .campaign-carousel .react-multi-carousel-dot-list {
    margin-top: 54vh !important;
    bottom: unset !important;
  }
  .dashboard-buttons {
    position: absolute;
    padding: 2rem 1rem 0rem;
    bottom: unset !important;
    margin-top: 59vh !important;
  }
}
@media screen and (max-width: 1207px) {
  .dashboard-buttons button.view_menu {
    margin-bottom: 0.8rem;
  }
}

@media screen and (min-width: 1207px) {
  .dashboard-buttons .group_order {
    display: none !important;
  }

  .campaign-carousel .react-multi-carousel-dot-list {
    margin-bottom: 5rem;
  }
}
@media screen and (min-width: 1024px) {
  .campaign-carousel .react-multi-carousel-track li {
    /* width: auto !important; */
    /* width: 100vw !important; */
    height: calc(var(--page_height) - 55px);
  }
  .favorite-item-detail div span:first-child {
    font-family: "Branden Grotesque Regular" !important;
  }

  .favorite-item-detail {
    text-align: left;
    margin-left: 9rem;
  }

  .favorite-item-detail div {
    flex-direction: column;
  }
  .favorite-item-detail div span:last-child {
    padding-left: 0px;
  }

  .favorite-item-detail span {
    font-size: 2.7rem;
  }

  .favorite-item-detail > span {
    font-size: 1.5rem;
  }

  .dashboardImage {
    background-size: cover;
  }

  .dashboard-image-text {
    width: 359px;
    bottom: 22vh;
    right: 10vw;
  }
}
@media screen and (max-width: 1024px) {
  .dashboardImage {
    width: 100vw;
  }
}

/* .react-multi-carousel-track li {
  width: auto !important;
} */



/* html{
scrollbar-width: none !important;
} 
body{
    overflow-y: hidden !important;
} */

