.suggestionItem {
  border: 1px solid var(--white1);
  margin-right: 12px;
}

/* .suggestionItem h3 {
  width: min-content;
} */

.suggestionItem img {
  width: 120px;
}

.delivery-info-icon {
  width: 20px;
}

.right-icon {
  width: 8px;
}

.location_info p {
  line-height: 1.1;
  margin-top: 5px;
}

.car-image {
  width: 80px;
}

.utensils-selected {
  border-bottom: 1px solid var(--orange1);
}

.location_info .locations_div > div {
  height: calc(100vh - calc(100vh - var(--page_height) + 5px));
}

.location_info .locations_div {
  position: fixed;
  top: calc(100vh - var(--page_height) - 40px);
  border: 1px solid var(--white1);
  /* border-left: 1px solid var(--white1); */
  /* border-top: 1px solid  var(--white1); */
}

.location-list-item {
  width: 25px;
  height: 25px;
  border: 2px solid #000000bd;
  border-radius: 100%;
}

.location-list-item span {
  width: 15px;
  height: 15px;
  background-color: #000000bd;
  border-radius: 100%;
}

.delivery-instructions-field textarea {
  font-family: "Brown STD Regular" !important;
  border: 1px solid var(--grey4);
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
}

.delivery-instructions-field .MuiInput-underline:before,
.delivery-instructions-field .MuiInput-underline:after {
  border: none !important;
}

.delivery-instructions-field label {
  padding: 10px;
  color: var(--white3) !important;
}

.delivery-instructions-field label.MuiInputLabel-shrink {
  display: none;
}

.delivery-instructions-PopUp .MuiDialog-paper {
  width: 100%;
  max-width: 30rem;
}

.reward-item {
  border: 1px solid;
}

.cart-image-border {
  border: 1px solid var(--grey3);
}

.checkout {
  border-top: 1px solid var(--white1);
}

.customize_modal .MuiDialog-paperScrollPaper {
  width: 100% !important;
  max-height: 70vh;
}

.close-confirm-reward {
  width: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.promo-code-icon {
  margin-left: -3px;
}

.paymentModel .MuiDialog-container {
  align-items: flex-end;
}

.customize_modal .MuiDialog-paperScrollPaper {
  max-height: 90vh;
}

.paymentModel .MuiDialog-paper {
  margin-bottom: 0;
}

.close_payment_modal {
  width: 19px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.big_dot {
  background-color: black;
  width: 5px;
  height: 5px;
  border-radius: 100px;
  display: inline-block;
  margin: auto 4px;
}

.textField.card_field > div::after,
.textField.card_field > div::before {
  border-bottom-width: 2px !important;
}

.payment_field,
.payment_field:focus {
  background-color: transparent;
  z-index: 1;
  padding-left: 0 !important;
  border-bottom: 2px solid var(--white1);
}

.payment_field.is-invalid,
.payment_field.is-invalid:focus {
  border-color: var(--orange1);
  background-image: none;
}

.payment_field + .invalid-feedback {
  color: var(--orange1);
  /* height: 20px; */
  margin-bottom: 1.5rem;
}

.payment_field ~ label {
  padding: 23px 0 0;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-1.4rem) translateX(0rem);
}

.card_item:first-child {
  border-top: 1px solid var(--grey4);
}

.card_item {
  padding: 2rem 0;
  border-bottom: 2px solid var(--white1);
}

.card_list {
  min-height: 600px;
}

.payment_card_image {
  border-bottom: 2px solid var(--white1);
  margin-top: 14px;
  padding-bottom: 12px;
  border-radius: 0;
}

input[aria-label="Card number"]::before {
  /* display: none; */
  content: "as";
  padding-left: 2rem;
}

.customize_modal.confirm-reward .MuiDialog-paperScrollPaper {
  width: 327px !important;
  background-image: url("../img/ConfirmReward.png");
  background-size: cover;
  margin: 0;
}

.customize_modal.confirm-reward .MuiDialogContent-root {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.Add-Card-From {
  min-width: 300px;
}
.Add-Card-From label {
  color: #767676;
}

.cart_order_detail {
  width: 100%;
  max-width: 52rem;
}

.add_to_group_order {
  font-size: 1.1rem;
  width: 20.375rem;
}

@media screen and (min-width: 1024px) {
  .add_to_group_order {
    font-size: 1.1rem;
    width: 24.375rem;
  }

  .cart_sections > div {
    border: 1px solid var(--white1);
    margin: 15px 15px 30px;
  }

  .customize_modal .MuiDialog-paperScrollPaper {
    width: 621px !important;
  }

  .customize_modal .MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd {
    width: 721px !important;
  }

  .customize_modal
    .MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd
    .MuiDialogContent-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .location_info .locations_div {
    top: calc(100vh - var(--page_height) + 44px);
  }

  .location_info .locations_div > div {
    height: calc(100vh - calc(100vh - var(--page_height) + 30px));
  }

  .customize_modal.confirm-reward .MuiDialog-paperScrollPaper {
    width: 327px !important;
    background-image: unset;
  }
}
