:root {
  --customizeWidth: 872px;
}

.menu-category {
  border-bottom: 1px solid var(--white1);
  white-space: nowrap;
  position: fixed;
  width: 100%;
  max-width: 100vw;
  z-index: 1;
  background: #fff;
  top: 75px;
  padding-top: 35px;
}

#customize-menu .selected,
.menu-category .selected {
  border-bottom: 2px solid;
  margin-bottom: 0px;
}

.singleMenuItem {
  border: 1px solid var(--white1);
  box-shadow: none !important;
  min-width: 310px !important;
  border-radius: 0 !important;
  flex: 1;
}

.categoryMenuItems {
  display: grid !important;
  grid-column-gap: 2rem;
}

.singleMenuItem .MuiCardMedia-root {
  height: 0;
  padding-top: 80%;
}

.menu-detail-section-row:before,
.menu-detail-section-row:after {
  border: 0 !important;
}

.menu-detail-section-row + .dropdown {
  width: calc(100% - 3rem);
  border: 2px solid var(--yellow1);
  border-top: 2px solid var(--brown2);
  max-height: 290px;
  overflow: auto;
  z-index: 1;
}

.menu-detail-section-row + .dropdown li {
  border-bottom: 1px solid var(--white1);
  list-style-type: none;
}

.menu-detail-section-row {
  border-bottom: 1px solid var(--white1);
}

.remove-ingredient {
  right: -13px;
  width: 30px;
  top: 5px;
}

.top-menu {
  max-width: var(--customizeWidth);
  margin: auto auto 0;
}

.react-multi-carousel-list {
  width: 781px;
  /* height: 140px; */
}

.carousel-button-group img:first-child {
  left: 30px;
}

.carousel-button-group img {
  width: 15px;
}

.customizeItem {
  width: 120px;
}

.packageCustomizeItem {
  min-width: 310px;
  width: 310px !important;
}

.customizeItemImage {
  width: max-content;
  margin: auto;
  /* height: 180px; */
}

.customizeItem .customizeItemImage {
  width: 90px;
  min-height: 90px;
  border-radius: 100%;
}

.custom-menu-item-group > div:nth-child(even) {
  background-color: #efebe5;
}

.custom-menu-item-group > div:nth-child(odd) {
  background-color: #eff1e5;
}

.custom-menu-item-group > div:last-child {
  padding-bottom: 6rem !important;
}

.customize-order-button {
  bottom: 0px;
}

.modifier-button-selected {
  border-bottom: 1px solid var(--orange1);
}

.modifier-button-unselected {
  border-bottom: 1px solid transparent;
}

#customize-menu {
  border-bottom: 1px solid var(--white1);
  white-space: nowrap;
  width: 100%;
  z-index: 1;
  background: #fff;
  top: 75px;
  padding: 20px 20px 0;
}

#main-menu .selected,
#customize-menu .selected {
  border-color: var(--grey4) !important;
  color: var(--grey4) !important;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.menu-customization-header {
  position: fixed;
  width: 100vw;
  background-color: white;
  z-index: 1;
  top: 90px;
}

.menu-customization-header-item {
  min-height: 320px;
}

.menu-customization-header > div:first-of-type {
  margin-top: 21px;
}

.menu-customization-header.package_customization {
  display: flex;
  flex-direction: column;
  min-height: auto;
}

.menu-customization-header.package_customization p {
  order: 2;
}

.customizeItem {
  position: relative;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.customizeItem:after {
  content: "";
  background: #dce4dc;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 1s;
}

.customizeItem:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.MuiCardActionArea-focusHighlight {
  background-color: transparent !important;
}

.package-customization-icon {
  width: 22px;
  height: 22px;
}

#customize-menu p:not(:last-child) {
  margin-right: 38px;
}

.remove-ingredient + img {
  height: 90px;
  width: 90px;
  border-radius: 100%;
}

.line-height-1_25 {
  line-height: 1.25;
}

.package-customization-arrows {
  width: 12px;
}

.ingredient-counter {
  background-color: var(--white5);
  border-color: 2px solid var(--white1);
  position: absolute;
  bottom: 0;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  line-height: 1;
}

.menu_cart_icon {
  position: fixed;
  right: 3rem;
  bottom: 3rem;
}

.menu-item:last-child {
  margin-right: 0 !important;
}

.menu-detail-image div {
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.menu-detail-image {
  height: 75vw;
}

.menu-item-description::first-letter {
  text-transform: uppercase;
}

.customize-top-text {
  color: var(--grey10);
  font-size: 12px;
}

.group_order_counter {
  border: 2px solid #382e23 !important;
  padding: 4px 8px;
  border-radius: 100%;
  background-color: white;
}

@media screen and (max-width: 560px) {
  .categoryMenuItems {
    grid-template-columns: 1fr !important;
  }
}

@media screen and (min-width: 900px) {
  .menu-customization-header > div:first-of-type {
    margin-top: -27px;
  }
  .menu-customization-header.package_customization {
    display: unset;
  }
}
@media screen and (min-width: 550px) {
  .customize-top-text {
    color: var(--brown2);
    font-size: 16px;
  }

  .scroll-container {
    margin-top: 0px;
  }

  .customizeItem {
    width: 150px;
  }

  .customizeItem .customizeItemImage {
    width: 110px;
    min-height: 110px;
  }
}

.active-scroll-spy {
  background-color: yellowgreen;
  border-radius: 15px;
}

#customize-menu.top-menu > p:first-child {
  margin-left: auto;
}

#customize-menu.top-menu > p:last-child {
  margin-right: auto !important;
}

.menu-customization-header h2 {
  width: 100%;
}

.group_info_model_detail h1,
.group_info_model_detail div {
  font-size: 13px;
}
.group_info_model_detail
  div.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
  font-size: 13px;
}

@media screen and (min-width: 768px) {
  .group_info_model_detail h1,
  .group_info_model_detail div {
    font-size: 18px;
  }
  .group_order_counter {
    width: 36px;
    height: 35px;
    border-radius: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    padding-bottom: 3px;
    position: absolute;
  }
}
@media screen and (min-width: 900px) {
  .menu-customization-header h2 {
    width: 29vw;
  }
}

@media screen and (min-width: 1024px) {
  .menu-customization-header {
    position: fixed;
    width: 100vw;
    background-color: white;
    z-index: 1;
    top: 95px;
  }

  .menu-customization-header-item {
    min-height: 287px;
  }

  .menu-detail-image {
    height: calc(var(--page_height) + 16px);
    right: 0;
    bottom: 0;
    left: 0;
  }

  .singleMenuItem .MuiCardMedia-root {
    height: 235px;
  }

  .menu-detail-section-row + .dropdown {
    width: 30%;
  }

  .customizeItem {
    width: 135px;
  }

  .customizeItem .customizeItemImage {
    width: 122px;
    min-height: 122px;
  }

  .customizeItem {
    width: calc(var(--customizeWidth) / 6);
  }

  #customize-menu {
    padding: 20px 0 0;
  }

  #customize-menu p:not(:last-child) {
    margin-right: 50px;
  }

  #customize-menu p:last-child {
    margin-right: auto;
  }
}
