:root {
  --locationsDivWidth: 490px;
}

.locations_div {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100vw;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  height: auto;
  overflow: auto;
}

.location_reorder_div {
  bottom: 0;
  top: unset;
  position: fixed !important;
  height: 88vh !important;
  z-index: 1;
}

.location_type {
  border: 2px solid;
}

.location_type span {
  cursor: pointer;
  background-color: white;
  color: var(--white2);
}

.location_type span:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.location_type span:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.location_type span.active {
  background-color: var(--grey4);
  color: white;
}

.location_item {
  border-bottom: 1px solid var(--grey4);
}

.map_view {
  position: relative;
  height: calc(var(--page_height) + 38px);
}

.slots_list img {
  width: 0.6rem;
}

.slots_list > div > * {
  border-bottom: 1px solid var(--white1);
  padding: 14px 0 10px;
  margin-bottom: 0;
  margin-left: 3rem;
  margin-right: 3rem;
}

.tag {
  cursor: pointer;
  border: 1px solid var(--white1);
  color: var(--brown2);
  background-color: transparent;
}

.tag.selected {
  color: white;
  background-color: var(--brown1);
  border-color: var(--brown1);
}

.selectedTime {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border: 0px;
}
.selectedTime:after {
  content: url("../img/SelectedTime.png");
  position: absolute;
  right: 3rem !important;
}

.gm-svpc + .gmnoprint {
  display: block !important;
  position: fixed !important;
  bottom: 77px;
  left: 15px !important;
  top: unset !important;
}

.gm-svpc {
  display: block !important;
  position: fixed !important;
  bottom: 30px;
  top: unset !important;
  left: auto !important;
}

.loader::after {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.time-confirm-button {
  position: fixed;
  width: var(--locationsDivWidth);
  max-width: 100vw;
  bottom: 0;
}

.search-area {
  position: absolute;
  top: 11.5rem;
  right: 10px;
  text-transform: capitalize;
  font-family: "Branden Grotesque Medium";
  font-size: 14px;
}

.locations_div .textFieldUpdate {
  margin-bottom: 0px !important;
}

.locations_div .textField.textFieldUpdate .MuiInput-underline:before,
.locations_div .textField.textFieldUpdate .MuiInput-underline:after {
  border: 0 solid transparent !important;
}

.locations_div .search-delivery-div {
  padding-bottom: 0.45rem;
}

.locations_div .search-delivery-div,
.locations_div .search-div {
  border-bottom: 1px solid var(--white1);
  margin-bottom: 8px;
}

.cart_locations_div {
  background-color: rgba(255, 255, 255, 1);
}

.cart_locations_div .time-confirm-button {
  padding-bottom: 20px;
}

.group_order_sign_in {
  position: fixed;
  right: 3%;
  top: 0%;
  z-index: 2;
  margin-top: 2.8rem;
}
.group_order_sign_in_with_cart {
  position: fixed;
  right: 20%;
  top: 0%;
  z-index: 2;
  margin-top: 2rem;
  width: 4rem;
}

.locations_sign_in {
  position: fixed;
  left: 3%;
  top: 0%;
  z-index: 2;
  margin-top: 2.8rem;
}

.locations_sign_up {
  position: fixed;
  left: 22%;
  top: 0%;
  z-index: 2;
  margin-top: 2.8rem;
}

@media screen and (min-width: 1025px) {
  .cart_locations_div {
    left: calc(50% - (var(--locationsDivWidth) / 2));
  }

  .time-confirm-button {
    position: sticky !important;
  }

  .map_view {
    height: calc(var(--page_height) + 18px);
  }

  .locations_div {
    height: inherit !important;
    max-width: var(--locationsDivWidth);
  }

  .view_map_button {
    display: none;
  }

  .search-area {
    top: 23px;
    right: calc(var(--locationsDivWidth) + 15px);
    left: unset;
    text-transform: uppercase;
    font-family: "Branden Grotesque Bold";
    font-size: 15px;
  }
}
