html {
  font-size: 16px;
  scrollbar-width: thin;
}

.menu {
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: flex;
  justify-content: center;
}

.submenu {
  position: absolute;
  left: 0;
  background-color: white;
  width: 100vw;
  height: auto;
  z-index: 99;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--white1);
}

.menuIcon {
  content: url("../img/Menu.png");
  z-index: 3;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 250ms ease-out 0s;
  width: 0;
}

.menuIcon.open {
  content: url("../img/CloseImg.png");
  margin-top: 9px;
  transform: rotate(-180deg);
  transition: transform 250ms ease-out 0s;
}

.menu .menuItems {
  margin-top: 70px;
  min-width: 75%;
  max-width: 700px;
}

.menu .menuItems p {
  text-transform: uppercase;
  font-family: "Branden Grotesque Bold";
  font-size: 2rem;
  color: var(--brown2);
  cursor: pointer;
  letter-spacing: 8px;
  line-height: 50px;
  margin: 0;
}

.menu .menuItems p:hover {
  color: var(--orange1);
}

.content {
  display: flex;
  flex: 1;
}

.main-panel {
  display: flex;
  flex-direction: column;
}

.row {
  margin: 0;
  padding: 0;
}

.row > * {
  width: unset;
}

.rounded-full {
  border-radius: 50px !important;
}
.rounded-half {
  border-radius: 20px !important;
}

.btn {
  border-width: 1px;
  border-style: solid;
}

.btn:focus,
.btn:active {
  box-shadow: none;
}

::placeholder {
  font-family: "Branden Grotesque Medium";
  color: var(--grey1) !important;
  font-size: 1rem;
}

.MuiFormControl-root label,
.MuiFormControl-root label span {
  font-family: "Branden Grotesque Medium";
  color: var(--grey1) !important;
  font-size: 1rem;
}

.MuiFormControl-root.textFieldUpdate label,
.MuiFormControl-root.textFieldUpdate label span {
  font-family: "Branden Grotesque Regular";
  font-size: 0.9rem;
  color: var(--white3) !important;
}

.date_picker label.MuiInputLabel-shrink,
.textField label.MuiInputLabel-shrink {
  font-family: "Branden Grotesque Regular";
  transform: scale(1);
}

.textField p.Mui-error {
  color: var(--orange1) !important;
  font-family: "Branden Grotesque Regular";
  font-size: 0.9rem;
}

.date_picker input {
  font-size: 1.1rem;
  color: var(--grey1);
  font-family: "Branden Grotesque Bold";
}

.date_picker button {
  padding-right: 0;
  margin-right: -3px;
}

.textField input {
  font-size: 1.3rem;
  color: var(--grey1) !important;
  font-family: "Branden Grotesque Medium";
}

.menuTextField input,
.menuTextField p {
  font-size: 0.9rem;
  color: var(--grey1) !important;
  font-family: "Brown STD Regular";
  padding: 14px 0;
}

.textFieldUpdate input {
  font-size: 1.3rem;
}
.textField input + div {
  display: none;
}
.textField[fieldtype="password"] input + div {
  display: flex;
}

.menuTextField .MuiInput-underline:before,
.menuTextField .MuiInput-underline:after {
  border-bottom: 1px solid var(--white1) !important;
}
.textField .MuiInput-underline:before,
.textField .MuiInput-underline:after {
  border-bottom: 1px solid var(--white1) !important;
}

.textField .MuiInput-underline.Mui-error:after {
  border-bottom: 1px solid var(--orange1) !important;
}

.textField .MuiInputAdornment-root p {
  font-family: "Branden Grotesque Bold";
  font-size: 0.7rem;
  color: var(--grey1);
  text-transform: uppercase;
  cursor: pointer;
}

.checkboxField {
  margin-right: 0 !important;
}

.checkboxField .MuiIconButton-label {
  color: transparent;
  border: 1px solid #c4c4c4;
  border-radius: 100px;
}

.checkboxField .Mui-checked .MuiIconButton-label {
  color: #000;
}

.checkboxField .MuiCheckbox-root:hover,
.checkboxField .MuiCheckbox-root.Mui-checked:hover {
  background-color: unset;
}

.MuiPickersDay-daySelected {
  background-color: var(--grey1) !important;
}

.react-tel-input input {
  width: 100% !important;
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid var(--white1) !important;
  font-family: "Branden Grotesque Bold";
  font-size: 1.1rem !important;
  padding-bottom: 8px !important;
  color: var(--grey1) !important;
}

.react-tel-input .special-label {
  left: 0 !important;
  padding: 0 !important;
  font-family: "Branden Grotesque Regular";
  color: var(--grey1);
  font-size: 1rem !important;
}
.react-tel-input .special-label::after {
  content: " *";
  font-family: "Branden Grotesque Medium";
  color: var(--grey1);
  font-size: 1rem !important;
}

.react-tel-input .selected-flag {
  height: 120% !important;
  padding: 0 !important;
}

.btn[disabled] {
  background-color: var(--white1) !important;
  border-color: var(--white1) !important;
  color: var(--grey3) !important;
}

.form-control {
  border-width: 0 0 thin 0;
  border-radius: 0;
  font-family: "Branden Grotesque Medium";
  font-size: 19px;
}

.form-control:focus {
  box-shadow: none !important;
}

.was-validated .form-control:invalid {
  border-color: #ff6418 !important;
}

input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label {
  top: 8px;
  bottom: 10px;
  font-size: 11px;
  opacity: 1;
}

.formField {
  position: relative;
  margin-bottom: 5px;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  top: 10px;
  transition: 0.2s ease all;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* width */
.locations_div::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.default-letter-spacing {
  letter-spacing: 0.12em;
}
.category-letter-spacing {
  letter-spacing: 0.05em;
}

.cursor-pointer {
  cursor: pointer;
}

.white-pre-line {
  white-space: pre-line;
}

.top-border {
  border-top: 2px solid var(--white1);
}

.line-height-0 {
  line-height: 0;
}

.line-height-1 {
  line-height: 1;
}

.flex-1 {
  flex: 1;
}

.mix-blend-mode {
  mix-blend-mode: multiply;
}

.navBar {
  position: sticky;
  top: 0;
  z-index: 2;
  transition: z-index 1s step-end;
}

.navBar.open {
  z-index: 5;
  transition: z-index 1s step-start;
}

.btn-half {
  width: 156px;
  height: 44px !important;
}

.border-bottom-1 {
  border-bottom: 1px solid var(--white1);
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.customize_modal .MuiDialog-paperScrollPaper {
  background-color: var(--white4);
  border-radius: 8px;
  width: 270px;
}

.customize_modal .MuiBackdrop-root {
  background-color: #00000045;
}

.customize_modal .MuiDialogContent-root {
  padding: 31px 8px 22px !important;
  border-bottom: 1px solid var(--white1);
}

.customize_login_modal .MuiDialogContent-root {
  padding: 31px 0 22px !important;
}

.barcode_modal.customize_modal .MuiDialogContent-root {
  padding: 31px 0px 22px !important;
}

.customize_modal .MuiDialog-paperScrollPaper p {
  font-family: "SF Pro Display Regular";
  font-size: 0.8rem !important;
  color: black;
  text-align: center;
  margin-bottom: 0;
}

.customize_modal .MuiDialog-paperScrollPaper h2 {
  font-size: 1rem;
  color: black;
  font-family: "SF Pro Display SemiBold";
  text-align: center;
  margin-bottom: 18px;
}

.customize_modal .MuiDialog-paperScrollPaper button {
  margin-bottom: 10px;
  color: #007aff;
  /* font-size: 1rem; */
  font-size: clamp(0.75rem, 3vw, 1rem);
  font-family: "SF Pro Display SemiBold";
  height: 36px;
}

.circular-progress-bar {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000038;
  z-index: 999;
}
.cart-circular-progress-bar {
  z-index: 1301;
}
.circular-progress-bar div {
  width: 30px;
  height: 30px;
  color: white;
}

.menu-back-icon {
  content: url("../img/Back.png");
  width: 24px;
}

.menu-close-icon {
  content: url("../img/CloseCircle.svg");
  width: 48px;
}

.user-menu {
  position: fixed;
  width: calc(100% - 20px);
  height: auto;
  left: 10px;
  right: 10px;
  top: 70px;
  background: white;
  border: 1px solid var(--white1);
  border-radius: 10px;
}
.group-menu {
  width: 300px !important;
  right: 120px !important;
}

.back-image {
  z-index: 2;
  position: fixed;
  left: 16px;
  top: 45px;
  content: url("../img/Back.png");
  width: 24px;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.register-page-footer {
  position: sticky;
  z-index: 1000;
}

.text-right {
  text-align: right;
}

.react-multi-carousel-dot button {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.top_menu_section > *:nth-child(1) > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media screen and (max-width: 1206px) {
  .top_menu_section > *:nth-child(1) {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .customize_modal .MuiDialogContent-root {
    padding: 31px 20px 22px !important;
  }

  .menu .menuItems p {
    font-size: 3.3125rem;
    line-height: 75px;
  }

  .menuIcon {
    width: 28px !important;
    /* height: 18px; */
  }

  .back-image {
    position: fixed;
    left: 0;
    top: 100px;
    width: 30px;
  }

  .locations_div .back-image {
    position: absolute;
    left: 0;
    top: 0px;
    width: 30px;
  }

  .menu-detail-back-icon {
    position: fixed;
    z-index: 2;
  }
}

.cart_text {
  left: 0;
  width: 100%;
  text-align: center;
  top: 3%;
}

.checkout_header {
  position: absolute;
  margin-left: calc(1rem - 17px);
}

.text-left {
  text-align: left !important;
}

.menuIcon.loggedin ~ .greeting-text {
  position: absolute;
  left: 50px;
}

.footer button {
  border: none;
  background: #222;
  height: 40px;
  margin-left: 20px;
}

.footer button:hover {
  background-color: #fc642a;
}

.footer span.cursor-pointer:hover {
  color: #fc642a !important;
}

.footer input {
  border: none;
  background: #f9f9f9;
  font-weight: 400;
  padding: 0 19px;
  font-size: 13px;
  color: #a7a7a7 !important;
  line-height: 30px;
  height: 40px;
  font-family: "Brown STD Light" !important;
}
.footer .MuiInput-formControl::after,
.footer .MuiInput-formControl::before {
  border: 0 !important;
}
.z_index_2 {
  z-index: 2;
}

.first_letter::first-letter {
  text-transform: uppercase;
}

.reorder_time_slots {
  position: absolute !important;
  z-index: -99 !important;
}

@media screen and (min-width: 1024px) {
  .menuIcon.loggedin ~ .greeting-text {
    left: 70px;
  }

  .btn {
    border-width: 3px;
    height: 55px;
  }

  .btn-half {
    border-width: 2px !important;
  }

  .user-menu {
    position: fixed;
    width: 380px;
    height: auto;
    left: unset;
    right: 20px;
    top: 70px;
    background: white;
    border: 1px solid var(--white1);
    border-radius: 10px;
  }

  .user-menu .menuItems {
    margin-top: 0;
  }

  .user-menu .menuItems section:not(:last-child) {
    border-bottom: 1px solid var(--white1);
  }
}

body {
  overflow-y: scroll !important;
  /* padding-right: 8px !important; */
}

.MuiPickersDay-current {
  background-color: #80808033 !important;
}
.MuiPickersDay-current span p {
  font-weight: bold;
  color: black;
}
