.orders_history {
  /* max-width: 756px; */
  min-height: 86vh;
  position: relative;
}

.order_type {
  /* width: calc(100% - 2rem); */
  padding: 2rem 0;
  position: fixed;
  height: calc(44px + 3rem);
  z-index: 1;
}
.order_type > div {
  position: fixed;
  left: calc(50% - 195px);
  border: 2px solid !important;
}

.order_type .active {
  background-color: var(--grey4);
}

.order_type span:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.order_type span:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.orders_history_list {
  padding-top: calc(44px + 4rem);
  z-index: 0;
  width: 756px;
  margin: auto;
  max-width: calc(100vw - 2rem);
}

.tracking {
  border: 1px solid var(--white1);
}

.track_page {
  height: var(--page_height);
}

.tracking_map {
  max-width: 808px;
}

/* .tracking_map .gm-svpc,
.tracking_map .gmnoprint {
  display: none;
} */

.tracking_map .gm-svpc {
  display: none !important;
}
.tracking_map .gmnoprint {
  right: unset !important;
  position: absolute !important;
  top: 109%;
}

.tracking_map .tracking_map {
  position: relative !important;
  width: 100vw !important;
  height: 40vh !important;
}

@media screen and (max-width: 550px) {
  .orders_history_img {
    /* background: linear-gradient(0deg, #ffffff 71%, rgba(255, 255, 255, 0) 100%),
      url("../img/OrdersImage.svg"); */
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 13%),
      url("../img/OrdersImage.svg");
    position: fixed;
    top: 22vh;
    left: 0;
    width: 100vw;
    height: 80vh;
    /* background-image: url("../img/OrdersImage.svg"); */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .orders_history:has(.location_info) .orders_history_img {
    background-image: none;
  }
}
