// SCSS will create styling according to mobile first approach

// Width classes creation from w-1 to w-100 in percentage So that widths can be used anywhere we want instead of setting width in each element styling
@mixin make-width($media) {
  @for $i from 1 through 100 {
    // .w-#{$i} {
    .w-#{$media}#{$i} {
      width: #{$i}#{"%"} !important;
    }
  }
}

// Create padding, margin for mobile devices
@include make-width("");

// Create padding, margin for Tablet devices
@media screen and (min-width: 580px) {
  @include make-width("sm-");
}

// Create padding, margin for laptop devices
@media screen and (min-width: 1024px) {
  @include make-width("md-");
}

// Create padding, margin for large devices
@media screen and (min-width: 1360px) {
  @include make-width("lg-");
}

// Width some specific classes creation in rem So that widths can be used anywhere we want instead of setting width in each element styling
$widthArray: (
  "326": 20.375,
  "340": 21.25,
  "320": 20,
  "268": 16.75,
  "168": 10.5,
  "120": 8,
  "390": 24.375,
  "832": 52,
  "93": 5.8125,
  "85": 5.3125,
  "240": 15,
  "233": 14.5625,
  "22": 1.375,
  "32": 2,
  "50": 3.125,
  "65": 4.063,
);

@each $name, $width in $widthArray {
  .width-#{$name} {
    width: #{$width}#{"rem !important"};
  }
}

// height some specific classes creation in rem So that heights can be used anywhere we want instead of setting height in each element styling
$heightArray: (
  "38": 2.375,
  "30": 1.875,
  "44": 2.75,
  "0": 0,
  "32": 2,
);

@each $name, $height in $heightArray {
  .height-#{$name} {
    height: #{$height}#{"rem !important"};
  }
}

// Create font size classes from .7rem to 2.5rem So that font sizes can be used anywhere we want instead of setting font size in each element styling
@function str-replace($string, $search, $replace) {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@for $i from 07 through 35 {
  $name: $i/10;

  $name: str-replace("" + $name, ".", "_");

  $index: str-index("" + $name, "_");

  @if ($i < 10 and $index == 1) {
    $name: "0" + $name;
  }

  .font-size-#{$name} {
    font-size: #{$i / 10}#{"rem"} !important;
  }
}

// Create padding, margin classes from 1 to 100 px for all sides including media devices So that padding can be used anywhere we want instead of setting padding in each element styling
@mixin make-padding($media, $key) {
  // set attr default value as it received. it can be p OR m for padding and margin accordingly
  $attr: $key;

  // set attrValue default value to padding
  $attrValue: "padding";

  // if its called for margin then set the attrValue key to margin
  @if $key == "m" {
    $attrValue: "margin";
  }

  @for $i from 0 through 150 {
    .c#{$attr}-#{$media}#{$i} {
      #{$attrValue}: #{$i}#{"px"} !important;
    }

    .c#{$attr}x-#{$media}#{$i} {
      #{$attrValue}-left: #{$i}#{"px"} !important;
      #{$attrValue}-right: #{$i}#{"px"} !important;
    }

    .c#{$attr}y-#{$media}#{$i} {
      #{$attrValue}-top: #{$i}#{"px"} !important;
      #{$attrValue}-bottom: #{$i}#{"px"} !important;
    }

    .c#{$attr}t-#{$media}#{$i} {
      #{$attrValue}-top: #{$i}#{"px"} !important;
    }

    .c#{$attr}b-#{$media}#{$i} {
      #{$attrValue}-bottom: #{$i}#{"px"} !important;
    }

    .c#{$attr}l-#{$media}#{$i} {
      #{$attrValue}-left: #{$i}#{"px"} !important;
    }

    .c#{$attr}r-#{$media}#{$i} {
      #{$attrValue}-right: #{$i}#{"px"} !important;
    }
  }
}

// Create padding, margin for mobile devices
@include make-padding("", "p");
@include make-padding("", "m");

// Create padding, margin for Tablet devices
@media screen and (min-width: 580px) {
  @include make-padding("sm-", "p");
  @include make-padding("sm-", "m");
}

// Create padding, margin for laptop devices
@media screen and (min-width: 1024px) {
  @include make-padding("md-", "p");
  @include make-padding("md-", "m");
}

// Create padding, margin for large devices
@media screen and (min-width: 1360px) {
  @include make-padding("lg-", "p");
  @include make-padding("lg-", "m");
}

// Create text, background color and border colors classes So that colors and background colors can be used anywhere we want instead of setting colors in each element styling
$colorsArray: (
  "grey1": #404041,
  "grey2": #828282,
  "grey3": #adadad,
  "grey4": #382e23,
  "grey5": #646464,
  "grey6": #40404180,
  "grey7": #88807b,
  "grey8": #b0afaf,
  "grey9": #a7a7a7,
  "grey10": #9e9e9e,
  "white1": #e9e7e2,
  "white2": #c4c4c4,
  "white3": #a0a0a0,
  "white4": #f7f7f7,
  "white5": #fffffe,
  "white6": #ffffffde,
  "orange1": #ff6418,
  "green1": #42753b,
  "green2": #55a51c,
  "green3": #78a30d,
  "green4": #57a42a,
  "brown1": #382e24,
  "brown2": #382b23,
  "yellow1": #eff1e5,
  "black1": #42414d,
  "black2": #2b2a33,
  "red1": #c74f4c,
  "red2": #923e14,
);

@each $name, $color in $colorsArray {
  .text-#{$name} {
    color: $color !important;
  }

  .bg-#{$name} {
    background-color: $color !important;
  }

  .border-#{$name} {
    border-color: $color !important;
  }
}

// CREATE VARIABLE
:root {
  --grey1: #404041;
  --grey2: #828282;
  --grey3: #adadad;
  --grey4: #382e23;
  --grey5: #646464;
  --grey6: #40404180;
  --grey7: #88807b;
  --grey8: #b0afaf;
  --grey9: #a7a7a7;
  --grey10: #9e9e9e;
  --grey11: #a8a8a8;
  --white1: #e9e7e2;
  --white2: #c4c4c4;
  --white3: #a0a0a0;
  --white4: #f7f7f7;
  --white5: #fffffe;
  --white6: #ffffffde;
  --orange1: #ff6418;
  --green1: #42753b;
  --green2: #55a51c;
  --green3: #78a30d;
  --green4: #57a42a;
  --brown1: #382e24;
  --brown2: #382b23;
  --yellow1: #eff1e5;
  --black1: #42414d;
  --black2: #2b2a33;
  --red1: #c74f4c;
  --red2: #923e14;
  --page_height: calc(100vh - 1rem - 100px);
}
