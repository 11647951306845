.home-buttons {
  background: linear-gradient(0deg, #ffffff 71%, rgba(255, 255, 255, 0) 100%);
  bottom: -100px;
  /* background-image: url("../img/HomeShadow.svg"); */
  height: auto;
}

.fieldHint {
  position: absolute;
  top: 60px;
}

.auth-forms > div:first-child {
  z-index: 4;
}

.auth-forms .image_page {
  position: absolute;
  z-index: 3;
  mix-blend-mode: multiply;
  width: calc(100vw - 20%);
}

.auth-forms .image_page .authImage {
  background-image: url("../img/Signup.jpg");
  width: calc(100vw - 20%);
  height: 400px;
  position: absolute;
  /* top: -40px; */
  top: -92px;
  background-repeat: no-repeat;
  background-position: right top;
  left: 20%;
}

.auth-forms {
  min-height: calc(var(--page_height) + 30px);
}

.auth-forms .image_page.login_page .authImage,
.auth-forms:has(.otp_view) .authImage {
  background-image: url("../img/Signin.jpg");
}

.auth-forms .image_page.forget_password_page .authImage {
  position: fixed;
  background-image: url("../img/Forget_password.svg");
  background-position: bottom right;
  bottom: 0;
  top: unset;
  left: 0;
  background-size: contain;
  right: 0;
  width: 100%;
}

.signup-page-links,
.signup-page-links:hover,
.signup-page-links:focus {
  color: var(--orange1);
}

.home-page div:first-child {
  position: absolute;
}

.homeImage {
  height: calc(var(--page_height) - 9vh);
  background-image: url("../img/Auth_home_web.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.homepage_instruction {
  position: absolute;
  /* top: 0; */
  bottom: 140px;
}

.MuiPickersBasePicker-container .MuiToolbar-regular {
  background-color: #382b23 !important;
}

.MuiPickersBasePicker-container .MuiToolbar-regular button:first-child span {
  border-bottom: 2px solid white;
  margin-bottom: 8px;
}

.MuiPickersBasePicker-container .MuiToolbar-regular button:first-child span h6 {
  line-height: 1;
  padding-bottom: 5px;
}

@media screen and (min-width: 1024px) {
  .homeImage {
    height: var(--page_height);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
  }

  .homepage_instruction {
    bottom: 90px;
  }

  .home-buttons {
    /* background: linear-gradient(0deg, #ffffff 34%, rgba(255, 255, 255, 0) 49%); */
    background: unset;
    background-image: url("../img/HomeShadow.svg");
    height: 270px;
    /* height: 200px; */
    bottom: 0;
  }

  .home-buttons button:first-child {
    order: 2;
  }

  .auth-forms .image_page {
    position: unset;
  }

  .authImage {
    width: 50% !important;
    top: 0 !important;
    height: 100vh !important;
    position: absolute !important;
    background-size: cover !important;
    background-position: 50% !important;
    left: unset !important;
    background-image: url("../img/Auth_image.svg") !important;
    transform: rotate(0deg) !important;
    z-index: 999;
  }

  .auth-forms .image_page.signup_page .authImage {
    position: fixed !important;
  }

  .forgot-password {
    color: var(--brown2);
  }
}
